#vertical-center {
    min-height: 100%;
    /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh;
    /* These two lines are counted as one :-)       */
    align-items: center;
}

.grid {
    display: grid;
    place-items: center;
}

#user {
    color: crimson;
}